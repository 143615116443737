/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import includes from 'lodash/includes';
import compact from 'lodash/compact';

import { withStyles, CircularProgress, IconButton, TextField, Typography, MenuItem, Select } from '@material-ui/core';

import { Clear } from '@material-ui/icons';

// eslint-disable-next-line no-unused-vars
import { KlevuSearchSorting, KlevuDomEvents, KlevuListenDomEvent, FilterManager } from '@klevu/core';

import { useContent } from '../../../context/ContentContext';
import { settings as siteSettings } from '../../../../sites';

import { getContentSearchResults, getSettings } from '../../../../utils/klevuUtils';
import { useContentSearchContext } from '../../../context/ContentSearchContext';
import SearchResultListElement from './SearchResultListElement';
import { isSSR } from '../../../../utils/windowUtils';
import { breakPoints } from '../../../../constants';
import { useKlevuSearch } from '../../../context/KlevuSearchContext';
import SearchPagination from '../../../search/SearchPagination';
import SearchFilters from '../../../search/SearchFilters';
import SearchFilterDrawer from '../../../search/SearchFilterDrawer';

import Visible from '../../../shared/Visible';

const manager = new FilterManager();

const styles = theme => ({
  root: {
    minHeight: '1000px',
  },
  input: {
    marginBottom: theme.spacing(4),
  },

  minusMargin: {
    [theme.breakpoints.up('1000')]: {
      margin: '0 -100px',
    },
    [theme.breakpoints.up('1240')]: {
      margin: '0 -220px',
    },
  },
  noMinusMargin: {
    margin: '0',
  },

  filterDrawerContainer: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  filters: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: '300px',
      display: 'block',
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(3),
    },
  },
  resultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: { width: '85%' },
  },
  results: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  resultCount: {
    fontWeight: 'bold',
  },
  pagination: {
    width: '100%',
    [theme.breakpoints.down(400)]: { width: 'auto' },
    [theme.breakpoints.up('md')]: { width: 'auto' },
  },
  topSpacing: {
    marginTop: theme.spacing(3),
  },
  topFilters: {
    margin: theme.spacing(3, 0),
    flexWrap: 'wrap',
    gap: '24px 8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function ContentSearch({ classes, className, data, pageData }) {
  const { t } = useTranslation();
  const pageTitle = get(pageData, 'title');
  const { isInputOpen } = useKlevuSearch();

  const { getSearchTerm, updateSearchTerm } = useContentSearchContext();

  const contentListRef = useRef(null);
  const searchInputRef = useRef(null);
  const [enableMinusMargin, setEnableMinusMargin] = useState(false);

  const [filters, setFilters] = useState([]);

  const [searchTerm, setSearchTerm] = useState(getSearchTerm(pageTitle));
  const [klevuSettings, setKlevuSettings] = useState();
  const [searchInProgress, setSearchInProgress] = useState(true);
  const [searchResponseContent, setSearchResponseContent] = useState(undefined);
  const [sorting, setSorting] = useState(KlevuSearchSorting.NewArrivalDesc);

  const [currentContentPage, setCurrentContentPage] = useState(1);

  const pageSize = 36;

  const searchPlaceholderText = get(data, 'searchPlaceholderText');
  // const pageType = get(pageData, 'internal.type');
  const showAllResultsByDefault = get(data, 'showAllResultsByDefault');
  const contentType = get(data, 'contentType', []);
  const filterCategories = get(data, 'filterCategories', []);
  const { getContentData } = useContent();

  const defaultFilters = useMemo(() => {
    const categoryTitles = map(filterCategories, 'title');
    const contentTypesFilter = map(contentType, element => {
      const value = t(`KlevuSearch.contentSearch.defaultFilters.contentType.${element}`);
      if (!includes(value, '.')) {
        return value;
      }
    });

    const contentTypeFilter = [{ key: 'pageType', values: contentTypesFilter, settings: { singleSelect: false } }];
    const categoryFilter = [{ key: 'categories', values: compact(categoryTitles), settings: { singleSelect: false } }];

    return concat(contentTypeFilter, categoryFilter);
  }, [contentType, filterCategories, t]);

  const setMargin = () => {
    const moduleWidth = get(contentListRef, 'current.clientWidth');
    if (moduleWidth > breakPoints.lg) {
      setEnableMinusMargin(false);
    } else {
      setEnableMinusMargin(true);
    }
  };

  const fetchKlevuSettings = async () => {
    const settings = await getSettings();
    setKlevuSettings(settings);
    return settings;
  };

  const searchContent = useCallback(
    async (term = '') => {
      setSearchInProgress(true);
      if (!klevuSettings) {
        await fetchKlevuSettings();
        return;
      }

      if (term.length < 3 && term !== '*') {
        setSearchResponseContent([]);
      } else {
        const searchResults = await getContentSearchResults(
          term,
          klevuSettings,
          manager,
          sorting,
          pageSize,
          defaultFilters,
        );

        const channel = get(siteSettings, 'channels.0');
        const contentData = getContentData(channel);
        const resultsFilters = get(searchResults, 'cms.query.filters', []);
        const resultsNames =
          resultsFilters.length > 0 && resultsFilters[0].options
            ? resultsFilters[0].options.map(option => option.name)
            : [];

        const categoryTitles = contentData.categories
          .filter(category => category.title)
          .map(category => category.title);

        const filteredResults = resultsNames.filter(filter => {
          return categoryTitles.some(title => title.trim().toLowerCase() === filter.trim().toLowerCase());
        });

        const updatedSearchResults = { ...searchResults };
        const updatedFilters = updatedSearchResults.cms.query.filters.map(filter => {
          if (filter.options) {
            filter.options = filter.options.filter(option => filteredResults.includes(option.name));
          }
          return filter;
        });

        updatedSearchResults.cms.query.filters = updatedFilters;

        setSearchResponseContent(updatedSearchResults);

        setFilters(manager.filters ?? []);
      }
      setSearchInProgress(false);
    },
    [defaultFilters, klevuSettings, sorting, getContentData],
  );

  const pageContentCount = useMemo(() => {
    return Math.ceil(parseInt(get(searchResponseContent, 'resultCount.content', []), 10) / parseInt(pageSize, 10));
  }, [searchResponseContent]);

  const handleFilterUpdate = () => {
    setFilters(manager.filters);
    searchContent(searchTerm);
  };

  useEffect(() => {
    if (showAllResultsByDefault === 'Yes' && isEmpty(searchTerm)) {
      searchContent('*');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContent]);

  useEffect(() => {
    searchContent(searchTerm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, sorting, klevuSettings]);

  useEffect(() => {
    if (!isSSR()) {
      setMargin();
      window.addEventListener('resize', setMargin);
    }
    const stop = KlevuListenDomEvent(KlevuDomEvents.FilterSelectionUpdate, handleFilterUpdate);

    return () => {
      window.removeEventListener('resize', setMargin);
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentListRef]);

  const handleSearchTermChange = useCallback(
    event => {
      const value = get(event, 'target.value');
      if (value.length > 2) {
        setTimeout(() => {
          setSearchTerm(value);
          updateSearchTerm(pageTitle, value);
        }, 100);
      }
    },
    [pageTitle, updateSearchTerm],
  );

  const handleSearchTermClear = useCallback(() => {
    setSearchTerm('*');
    updateSearchTerm(pageTitle, '*');
    searchContent('*');
    searchInputRef.current.value = '';
  }, [pageTitle, searchContent, updateSearchTerm]);

  const handleContentPageChange = async (event, pageNumber) => {
    const nextResponse = await searchResponseContent.cms.getPage({
      filterManager: manager,
      pageIndex: pageNumber - 1,
    });

    const _nextPageResult = nextResponse.queriesById('cms');
    const nextSearchResult = {
      cms: nextResponse.queriesById('cms'),
      suggestions: nextResponse.suggestionsById('suggestions')?.suggestions.map(i => i.suggest) ?? [],
      resultCount: {
        content: get(_nextPageResult, ['meta', 'totalResultsFound'], 0),
      },
    };

    setSearchResponseContent(nextSearchResult);
    setCurrentContentPage(pageNumber);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSortingChange = event => {
    setSorting(event.target.value);
    setCurrentContentPage(1);
  };
  return (
    <div className={clsx(classes.root, className)}>
      <div>
        <TextField
          className={classes.input}
          placeholder={searchPlaceholderText}
          type="text"
          inputRef={searchInputRef}
          onChange={handleSearchTermChange}
          autoFocus={!isInputOpen}
          InputProps={{
            endAdornment: (
              <Visible visible={!isEmpty(searchTerm)}>
                <IconButton onClick={handleSearchTermClear}>
                  <Clear />
                </IconButton>
              </Visible>
            ),
          }}
          fullWidth
        />
      </div>

      <Visible visible={searchInProgress}>
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      </Visible>
      <Visible visible={!searchInProgress && get(searchResponseContent, 'resultCount.content') === 0}>
        {get(klevuSettings, ['klevu_uc_userOptions', 'noResultsOptions', 'messages'], []).map((message, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography variant="body1" className={classes.topSpacing} paragraph key={i}>
            {get(message, 'message')}
          </Typography>
        ))}
      </Visible>
      <div
        className={clsx(classes.wrapper, enableMinusMargin ? classes.minusMargin : classes.noMinusMargin)}
        ref={contentListRef}>
        <Visible visible={!searchInProgress && get(searchResponseContent, 'resultCount.content') > 0}>
          <div className={classes.filters}>
            <Typography variant="h2" className={classes.filterSectionTitle}>
              {t('KlevuSearch.filter')}
            </Typography>
            <SearchFilters filters={filters} manager={manager} settings={klevuSettings} hideOptions={[pageTitle]} />
          </div>
          <div className={classes.resultWrapper}>
            <div>
              <Typography component="span" display="inline" className={classes.resultCount}>
                {get(searchResponseContent, 'resultCount.content')}
              </Typography>
              <Typography display="inline" style={{ marginLeft: '10px' }}>
                {t('KlevuSearch.contentSearch.resultsFount')}
              </Typography>
            </div>

            <div className={classes.topFilters}>
              <div className={classes.sorting}>
                <Typography variant="body2" display="inline" style={{ marginRight: '10px' }}>
                  {t('KlevuSearch.orderBy')}:
                </Typography>
                <Select size="small" value={sorting} onChange={handleSortingChange}>
                  <MenuItem value={KlevuSearchSorting.NewArrivalDesc}>
                    {t('KlevuSearch.orderByNewArrivalDesc')}
                  </MenuItem>
                  <MenuItem value={KlevuSearchSorting.NameAsc}>{t('KlevuSearch.orderByNameAsc')}</MenuItem>
                </Select>
              </div>
              <div className={classes.filterDrawerContainer}>
                <SearchFilterDrawer
                  filters={filters}
                  manager={manager}
                  settings={klevuSettings}
                  hideOptions={[pageTitle]}
                />
              </div>
              <div className={classes.pagination}>
                <SearchPagination
                  pageCount={pageContentCount}
                  justify="end"
                  location="top"
                  onChangeHandler={handleContentPageChange}
                  currentPage={currentContentPage}
                  pageSize={pageSize}
                />
              </div>
            </div>
            <div className={classes.results}>
              {map(get(searchResponseContent, 'cms.records', []), resultPage => {
                const id = get(resultPage, 'url');
                return <SearchResultListElement data={resultPage} key={id} />;
              })}
            </div>
            <SearchPagination
              pageCount={pageContentCount}
              onChangeHandler={handleContentPageChange}
              currentPage={currentContentPage}
              scrollTop
            />
          </div>
        </Visible>
      </div>
    </div>
  );
}

ContentSearch.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ContentSearch.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  data: null,
};

export default withStyles(styles)(ContentSearch);
